@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Quicksand:wght@300;400;500;600;700&display=swap');

* {
	font-family: 'Inter', sans-serif;
	/* font-family: 'Poppins', sans-serif;
  font-family: 'Quicksand', sans-serif; */
}

.ant-btn-primary {
	background-color: #1677ff;
}

.ant-switch {
	background-color: rgba(0, 0, 0, 0.25);
}
